import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import {
  decreaseQuantity,
  increaseQuantity,
  removeProductFromBag
} from '../../../infra/utils/BagActions'
import Currency from '../../../infra/utils/Currency'
import {PriceSpan} from '../../../shared/components/bag/BagStyles'
import GetImage from '../../../shared/components/Image'
import ComponentLoadingOverlay from '../../../shared/components/loading/ComponentLoadingOverlay'
import getTranslationNameFromArray from '../../../shared/utils/getTranslationNameFromArray'
import {
  AsideArticle,
  CheckoutOperators,
  CloseButton,
  ClosingIcon,
  LessOperator,
  OperatorNumber,
  Picture,
  PictureImg,
  PlusOperator,
  ProductColorSize,
  ProductFields,
  ProductGenre,
  ProductName,
  ProductPrice
} from '../CheckoutStyles'

const UpdateQuantityTypes = {increase: 1, decrease: 2}

const CheckoutBagItem = ({product, index, user, activeLanguage}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const {languageId, allowOrdersWithMoney} = user

  const displayCost =
    !product.isCredits && allowOrdersWithMoney ? (
      Currency.format(
        product.unitValueConverted * product.units,
        user.currencyCode
      )
    ) : (
      <PriceSpan>
        {product?.units}{' '}
        {product?.units === 1 ? (
          <Translate id='CART_CREDIT' />
        ) : (
          <Translate id='CART_CREDITS' />
        )}
      </PriceSpan>
    )

  const handleRemoveItem = async () => {
    setIsLoading(true)
    await removeProductFromBag(product.bagItemId, dispatch)
    setIsLoading(false)
  }

  const handleUpdateQuantity = async (type) => {
    setIsLoading(true)
    if (type === UpdateQuantityTypes.increase) {
      await increaseQuantity(product.bagItemId, dispatch, languageId)
    } else {
      await decreaseQuantity(product.bagItemId, dispatch, languageId)
    }

    setIsLoading(false)
  }
  return (
    <AsideArticle key={index}>
      <ComponentLoadingOverlay isLoading={isLoading}>
        <CloseButton onClick={handleRemoveItem}>
          <ClosingIcon />
        </CloseButton>
        <Picture>
          <PictureImg
            src={GetImage(
              product?.product?.productImage?.find((x) => x.cover === true)
            )}
            alt=''
          />
        </Picture>
        <ProductFields>
          <ProductGenre
            style={{
              color: user.configuration?.layoutColors?.contentText
            }}
          >
            {
              product.product?.mainCategory?.categoryTranslation.find(
                (x) => x.languageId === activeLanguage.code
              )?.name
            }
          </ProductGenre>
          <ProductName>
            {
              product.product?.productTranslation.find(
                (x) => x.languageID === activeLanguage.code
              )?.name
            }
          </ProductName>
          <ProductColorSize>
            {getTranslationNameFromArray(
              product.colorTranslation,
              languageId
            )}
            {product?.productCombination.size.nameEu !== '' &&
              ` / ${product?.productCombination.size.nameEu}`}
          </ProductColorSize>
        </ProductFields>
        <ProductPrice>{displayCost}</ProductPrice>
        <CheckoutOperators>
          <LessOperator
            onClick={() =>
              handleUpdateQuantity(UpdateQuantityTypes.decrease)
            }
          >
            -
          </LessOperator>

          <OperatorNumber>{product.units}</OperatorNumber>
          <PlusOperator
            onClick={() =>
              handleUpdateQuantity(UpdateQuantityTypes.increase)
            }
          >
            +
          </PlusOperator>
        </CheckoutOperators>
      </ComponentLoadingOverlay>
    </AsideArticle>
  )
}

CheckoutBagItem.propTypes = {
  product: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(CheckoutBagItem)
