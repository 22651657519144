import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {ReactComponent as Close} from '../../../assets/close.svg'
import {
  PrimaryTextColor,
  PageBackground,
  Lightgrey,
  Grey,
  TertiaryColor
} from '../../styles/_colors'
import {device} from '../../styles/_responsive'
import {
  BagText,
  BodyText,
  PanelTopSubTitle,
  Assistant500,
  MediaType,
  Assistant800,
  LabelText,
  Assistant,
  Assistant600,
  AddressSubTitle,
  AssistantBold
} from '../../styles/_texts'
import {EditButton} from '../../styles/BasicStyles'

export const MyBag = styled.section`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  left: 0;
  top: 0;
  text-align: right;
  opacity: 0;
  overflow: hidden;
  z-index: 2000;
  opacity: ${({open}) => (open ? 1 : 0)};
  visibility: ${({open}) => (open ? 'visible' : 'hidden')};
  transition: all 0.2s ease;
`

export const Bag = styled.aside`
  max-width: 380px;
  width: 100%;
  height: 100%;
  background: ${PageBackground};
  position: relative;
  text-align: left;
  right: 0;
  z-index: 12;
  // overflow: auto;
  padding: 5px 30px 0px 30px;
  border-left: 1px solid rgba(76, 76, 76, 0.16);
  float: right;
  transform: ${({open}) => (open ? 'scale(1,1)' : 'scale(0,1)')};
  transform-origin: right;
  transition: all 0.4s ease;

  @media ${device.laptopL} {
    max-width: 375px;
    border: none;
  }

  @media ${device.mobileL} {
    overflow: scroll;
    max-width: 100%;
  }
`

export const TopContainer = styled.div``

export const BottomContainer = styled.div`
  // height: 651px;
  position: relative;
  overflow: visible;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const BagRow = styled.div`
  padding: 30px 0 20px !important;
  overflow: hidden;
  border: none;
`

export const InnerBagRow = styled(BagRow)`
  border-bottom: 1px solid rgba(76, 76, 76, 0.16);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:last-of-type {
    padding: 15px 0;
    border: none;
    overflow: hidden;
  }
`

export const BagTitle = styled.p`
  letter-spacing: normal;
  font-family: ${Assistant800};
  font-size: ${BodyText};
  text-align: left;
  color: ${PrimaryTextColor};
  line-height: 2.5;
`

export const IconSkyproClose = styled(Close)`
  position: absolute;
  padding: 5px;
  right: 25px;
  top: 42px;
  color: ${PrimaryTextColor};
  font-size: ${BagText};
  text-transform: uppercase;
  font-family: 'Assistant';
  font-weight: bold;
  width: 30px;
  height: 30px;
  cursor: pointer;
`

export const BagItems = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
`

export const BagItemsDiv = styled.div``

export const BagItemsSpan = styled.span``

export const BagList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`

export const ListItem = styled.li`
  display: flex;
  font-size: 0;
  width: 100%;
`

export const Subtotal = styled.p`
  max-width: 40%;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-family: ${Assistant600};
  font-size: ${MediaType};
  color: ${PrimaryTextColor};
  vertical-align: middle;
  text-align: left;
  line-height: 2.5;
  letter-spacing: 0.5px;
  margin-bottom: 0;
`

export const SubtotalResult = styled.p`
  margin-bottom: 0;
  font-size: ${BodyText};
  line-height: 2.14;
  max-width: 60%;
  width: 100%;
  text-align: right;
  display: inline-block;
  font-family: ${Assistant600};
  color: ${PrimaryTextColor};
  vertical-align: middle;
  letter-spacing: 0.5px;
`

export const CheckoutButton = styled(EditButton)`
  width: auto;
`

export const ClearBag = styled.a`
  float: right;
  margin-top: 4px;
  font-family: ${Assistant};
  font-size: ${BodyText};
`

export const BagProductContainer = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid rgba(76, 76, 76, 0.16);
  overflow: hidden;

  &:last-of-type {
    border: none;
  }
`

export const BagProductWrapper = styled.div`
  position: relative;
  height: 137px;
`

export const RemoveItem = styled.div`
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -20px;
  padding: 10px;
  color: ${PrimaryTextColor};
  text-transform: uppercase;
  transform: rotate(45deg);

  &::before {
    font-family: ${Assistant};
    color: ${Grey};
    font-size: 30px;
    content: '+';
  }
`

export const ProductImage = styled.img`
  max-width: 90px;
  width: 100%;
  background: ${Lightgrey};
  display: inline-block;
  margin-right: 10%;
  vertical-align: top;
  pointer-events: none;
`

export const ProductInfoWrapper = styled.div`
  vertical-align: top;
  max-width: calc(58% + 2px);
  width: 100%;
  display: inline-block;
`

export const ProductInfo = styled.div`
  width: 100%;
`

export const ProductCategory = styled.span`
  font-family: ${Assistant500};
  font-size: ${MediaType};
  letter-spacing: 1.4px;
  text-align: left;
  display: block;
`

export const ProductName = styled.p`
  font-family: ${Assistant800};
  font-size: ${LabelText};
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${PrimaryTextColor};
  margin-bottom: 0;
`

export const ProductNameLink = styled(Link)`
  color: ${PrimaryTextColor};

  &:hover {
    color: ${PrimaryTextColor};
  }
`

export const ProductSize = styled.small`
  font-family: ${Assistant};
  font-size: ${BodyText};
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${PrimaryTextColor};
  display: block;
`

export const ProductCount = styled.div`
  padding: 10px 0;
  border-top: 1px solid rgba(76, 76, 76, 0.16);
  border-bottom: 1px solid rgba(76, 76, 76, 0.16);
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 60%;
  width: 100%;
  line-height: 2.14;

  &::before {
    content: '';
    width: 1px;
    height: 54px;
    background: rgba(76, 76, 76, 0.16);
    display: block;
    left: 50%;
    top: 0;
    position: absolute;
  }
`

export const Counter = styled.div`
  font-family: ${Assistant};
  font-size: ${BodyText};
  text-align: center;
  color: ${PrimaryTextColor};
  display: inline-block;
  width: calc(49% - 1px);
`

export const CounterIcon = styled.button`
  display: inline-block;
  width: 20%;
  text-align: center;
  color: ${PrimaryTextColor};
  border: 0;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`

export const CounterText = styled.p`
  display: inline-block;
  width: 40%;
  text-align: center;
  color: ${PrimaryTextColor};
  font-family: ${Assistant};
  font-size: ${BodyText};
  line-height: 2.5;
  letter-spacing: 0.5px;
  margin: 0;
`

export const Price = styled.div`
  font-family: ${Assistant};
  font-size: ${BodyText};
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: ${PrimaryTextColor};
  display: inline-block;
  width: calc(49% - 1px);
`

export const PriceSpan = styled.span``

export const SideBar = styled.div`
  right: ${({sideBar, open}) => (sideBar && open ? '380px' : '-150px')};
  transition: all 0.8s ease-in;
  position: fixed;
  height: 100%;
  top: 0;
  background: ${TertiaryColor};
  text-align: center;
  padding: 30px;
  z-index: 8;
  overflow: auto;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: self-start;
  align-content: flex-start;
  align-items: flex-start;

  transform: ${({open}) => (open ? 'scale(1,1)' : 'scale(0,1)')};
  transform-origin: right;
  // transition: transform 0.4s ease 0.4s;
  transition: ${({open}) =>
    open ? 'transform 0.4s ease 0.4s' : 'transform 0.4s ease 0s'};

  @media ${device.laptopL} {
    right: ${({sideBar, open}) => (sideBar && open ? '370px' : '-150px')};
  }

  @media ${device.mobileL} {
    right: -150px;
  }

  @media (max-width: 538px) {
    right: -150px;
  }

  @media (min-width: 538px) {
    right: 375px;
  }
`

export const SideBarContent = styled.div`
  padding: 30px;
  height: 100%;
  width: 100%;
  float: left;
  padding: 0;
`

export const SideBarTitle = styled.p`
  padding-top: 7px;
  font-family: ${Assistant};
  font-size: ${AddressSubTitle};
  line-height: 2.5;
  color: ${Grey};
  text-transform: uppercase;
  margin-bottom: 32px;
  text-align: center;
  width: 100%;
`

export const RecommendedList = styled.ul`
  margin: 0 auto;
  list-style: none;
  padding: 0;
`

export const Recommended = styled.li`
  display: block;
  width: 90px;
  transition: all 0.3s ease-in-out;
`

export const RecommendedImage = styled.img`
  height: auto;
  width: 100%;
  background: ${PageBackground};
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
`

export const RecommendedLink = styled(Link)`
  display: block;
  text-decoration: none;
  outline: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;

    ${RecommendedImage} {
      transform: scale(0.9);
      transition: transform 0.3s ease-in-out;
    }
  }
`

export const RecommendedName = styled.span`
  text-align: center;
  display: block;
  font-family: ${AssistantBold};
  font-size: ${PanelTopSubTitle};
  line-height: 0.83;
  color: ${Grey};
`

export const RecommendedPrice = styled.small`
  font-family: ${Assistant};
  font-size: 11px;
  line-height: 2.73;
  color: ${PrimaryTextColor};
  text-align: center;
  display: block;
`

export const BagRowNoResults = styled.div`
  padding: 30px 0;
  overflow: hidden;
  font-family: ${Assistant};
  color: ${PrimaryTextColor};
  font-size: ${BodyText};
`

export const NoItems = styled.p`
  text-align: left;
  letter-spacing: normal;
`
